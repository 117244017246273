import React, { useState, useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import IconButton from "@mui/material/IconButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import { CURRENT_USERS_QUERY, DELETE_TEACHER } from './../util/graphql';
import { useMutation, useQuery } from '@apollo/client'
import Delete from '@mui/icons-material/Delete';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import { AuthContext } from '../context/auth';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack'
import { Button } from '@mui/material';
import { Container } from '@mui/system';

export default function ApprovedUserList() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [teacherName, setTeacherName] = useState("")
  const { user } = useContext(AuthContext);
  const [deleteTeacher, setDeleteTeacher] = useState();
  //  const {loading , error, data, refetch} = useQuery( All_TUTORS_QUERY );




  const {
    loading: tutorLoading,
    error: tutorListError,
    data: allStudents,
    refetch: reloadStudents,
  } = useQuery(CURRENT_USERS_QUERY, {
    fetchPolicy: "no-cache",
  });
  console.log("addStudent");
  if (tutorLoading) {
    console.log("tutor Loading");
  } else
    console.log(allStudents.getAllValid);


  const [deleteUser] = useMutation(DELETE_TEACHER, {
    update() {
      setOpen(false);
      console.log('DELETED');
      reloadStudents();
      toast.success(teacherName+" Deleted", {
        position: toast.POSITION.TOP_CENTER
      });
    },

    variables: { deleteTeacherId: deleteTeacher },
    pollInterval: 500,
    onError(err) {


      toast.error(err.graphQLErrors[0].message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
    
  })

  function clickcell(val) {
    if (val.id) {
      console.log("HERE");
      console.log(val.row.firstName);
      setDeleteTeacher(val.id)
      setTeacherName(val.row.firstName)
      //  Student()
      return (val.id)
    }
  }

  function refreshIconClick() {
    reloadStudents();
  }
  function plusIconClick() {
    // submit()
  }

  function deleteIconClick(event) {
    setAnchorEl(event.currentTarget);
    if (open === true) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  function no() {
    setOpen(false)
  }
  function yes() {
    deleteUser()
  }

  const columns = [
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150
    }
    ,
    {
      field: 'email',
      headerName: 'Email',
      width: 200
    },
    {
      field: 'createdAt',
      headerName: 'Creation Date',
      width: 250
    }
    ,
    {
      field: 'message',
      headerName: 'School District',
      width: 150
    },
    {
      field: "Refresh",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        return (
          <IconButton
            onClick={refreshIconClick}
          >
            <RefreshIcon />
          </IconButton>
        );
      },
      renderCell: () => {
        return (
          <IconButton onClick={deleteIconClick}

          >
            <Delete />
          </IconButton>
        );
      }
    }
  ];




  return (

    <Container>
      <ToastContainer />
      <Popper open={open} anchorEl={anchorEl} placement={'bottom-start'} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ p: 2 }}>
              <Typography sx={{ p: 2 }}>Are you sure you want to delete "{teacherName}"?</Typography>
              <Stack direction="row" spacing={2}>
                <Button variant="contained" color="success" onClick={yes}>
                  YES
                </Button>
                <Button variant="outlined" color="error" onClick={no} >
                  No
                </Button>
              </Stack>
            </Paper>
          </Fade>
        )}
      </Popper>
      <h2>Approved Users</h2>
      <div style={{ height: 500, width: "100%" }}>
        {tutorLoading ? (<p>Loading..</p>) : (
          <DataGrid
            rows={allStudents.getAllValid}
            columns={columns}
            onCellClick={clickcell}
          />)}
      </div>
    </Container>
  )

}


// {allStudents ?      
//   <DataGrid
//     rows={allStudents.getStudents}
//     columns={columns}
//    onCellClick={clickcell}
//   /> : <h1>No Students</h1>}