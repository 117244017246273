import React, { useEffect, useContext, useState } from "react";

import { AuthContext } from "../context/auth";
import  RegisterForm  from '../Components/registerforms/resReg'


import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";


export default function ResetPasswordReq() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  console.log(user);
  const [openStudent, setStudentOpen] = React.useState(false);  
  const [open, setOpen] = React.useState(false);
    const instructorOpen = () => setOpen(true);
    const instructorClose = () => setOpen(false);
  
    const studentOpen = () => setStudentOpen(true);
    const studentClose = () =>setStudentOpen(false);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: '#9AD17A',
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  }}>
      <RegisterForm/>
    </div>
    </Box>
  );
}

 
