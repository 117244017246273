import React from "react";
import  RegisterForm  from '../Components/registerforms/instructorReg'
import Box from "@mui/material/Box";
import "react-toastify/dist/ReactToastify.css";


export default function Content() {

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: '#9AD17A',
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  }}>
      <RegisterForm/>
    </div>
    </Box>
  );
}

 
