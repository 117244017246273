import React, { useContext, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { gql, useMutation } from '@apollo/client';
import { useForm } from '../../util/hooks';
import { AuthContext } from '../../context/auth'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Pdf from '../../imgs/policy.pdf';  
import 'react-toastify/dist/ReactToastify.css';




const theme = createTheme();

export default function InstructorReg(props) {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  function navTerm() {
    navigate("/termsOfUse");
  }
  const { onChange, onSubmit, values } = useForm(registerUser, {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    message: ''
  });


  console.log("hereeeee");
  const [regUser, { loading }] = useMutation(REGISTER_INSTRUCTOR, {
    update(
      _,
      results
    ) {
      console.log("resssss");


      console.log(results)
      toast.success("Request Submitted! We will email you once your approved", {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => {
          window.location.href = "/";
        }
      });

    },
    onError(err) {
      console.log(err);
      toast.error(err.graphQLErrors[0].message, {
        position: toast.POSITION.TOP_CENTER
      });
    },
    variables: values
  });

  function registerUser() {
    console.log('hereere')
    regUser();
  }






  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />


        <Box
          className='formBack'
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Request Access
          </Typography>
          <Box component="form" onSubmit={onSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={values.firstName}
                  error={errors.firstName ? true : false}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lastName"
                  value={values.lastName}
                  error={errors.lastName ? true : false}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  id="email"
                  autoComplete="email"
                  value={values.email}
                  error={errors.email ? true : false}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="password"
                  value={values.password}
                  error={errors.password ? true : false}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  autoComplete="confirmPassword"
                  value={values.confirmPassword}
                  error={errors.confirmPassword ? true : false}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="message"
                  label="Request Message (Optional)"
                  type="text"
                  id="message"
                  autoComplete="message"
                  value={values.message}
                  error={errors.message ? true : false}
                  onChange={onChange}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
              <FormControlLabel control={<Checkbox required/>} label="Read and Agree to Terms of Use and Privacy Policy" />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              style={{
                backgroundColor: "#D13A28"
              }}
              sx={{ mt: 3, mb: 2 }}>
              <a href={Pdf} target="_blank">VIEW TERMS OF USE AND PRIVACY POLICY</a>
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Create
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
              </Grid>
            </Grid>
          </Box>
        </Box>

      </Container>
      <ToastContainer />
    </ThemeProvider>
  );
}

const REGISTER_INSTRUCTOR = gql`mutation Register($firstName: String!, $lastName: String!, $email: String!, $password: String!, $confirmPassword: String! $message: String) {
    register(firstName: $firstName, lastName: $lastName, email: $email, password: $password, confirmPassword: $confirmPassword, message: $message) {
      id
    }
  }`