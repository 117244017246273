import { FaSignInAlt, FaSignOutAlt, FaFile } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import { AuthContext } from '../context/auth';
import { useContext } from 'react'
import logo from '../imgs/logo.png';



function Header() {
  const location = useLocation();
  const { user, logout } = useContext(AuthContext);

  return (
    <>
      {location.pathname !== "/Login" ?
        <header className='header'
          sx={{
            backgroundColor: '#395CAA'
          }}
        >
          <div className='logoGroup'>
            <div>
              <Link to='/Content'>
                <img src={logo} width={150} />
              </Link>
            </div>
            <div className='userName'>
              {user ? (<h3>{user.email}</h3>) : (<h3></h3>)}
            </div>
          </div>

          {user ? (
            <div>
              {location.pathname !== "/Resources" ?
                <Link to='/Resources'>
                  <FaFile /> Resources
                </Link>
                :
                <Link to='/Content'>
                  <FaFile /> Content
                </Link>
              }
              <br></br>
              <Link onClick={logout} to='/Login'>
                <FaSignOutAlt /> Logout
              </Link>

              {user.role == 'ADMIN' ? (
                <div>
                  <Link to='/Content'>
                    Content
                  </Link>
                  <br></br>
                  <Link to='/AdminHome'>
                    Home
                  </Link>

                </div>)
                :
                ''}
            </div>
          ) : (
            <div>
              <Link to='/Login'>
                <FaSignInAlt /> Login
              </Link>
            </div>
          )
          }
        </header> : <></>
      }
    </>
  )
}

export default Header