import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';

export default function SlideShow(props) {
  const [loading, setLoading] = useState(true);
  const [selectedLink, setSelectedLink] = useState('');
  const [linkArray, setLinkArray] = useState({});
  const [selectedPart, setSelectedPart] = useState(null);

  useEffect(() => {
    switch (props.slide) {
      case '1':
        setLinkArray({
          'Part 1': 'https://docs.google.com/presentation/d/e/2PACX-1vTyJTrymGCdUZBrFCj3QMOJ9eNN0DEXOjEUuPbDTNLg4DtQXAb2aupLcj8C9X3xQPy5I2Xb5qQi1avE/embed?start=false&loop=false&delayms=60000',
          'Part 2': 'https://docs.google.com/presentation/d/e/2PACX-1vS7z1GMNtlUNal7hop7q8RWeBRdqOtkprDXst3UeH_4ZDXpV8lGfQALfFiVentbaGSpoebvXqXuzRmY/embed?start=false&loop=false&delayms=60000',
          'Part 3': 'https://docs.google.com/presentation/d/e/2PACX-1vSmTLvGkTm65JhgG3rZ5DbEktWT77Nbf-0be3hFO0MbJJZf-nSdBCFzfW1RvrBYoUY4daLi3KD90YhQ/embed?start=false&loop=false&delayms=60000',
          'Grammar': 'https://docs.google.com/presentation/d/e/2PACX-1vSlAC-w2rbShMA1DGmvvxOaLnUTB5GCGBoKF84DuGWapidzCgvDt90JnRMX6xl8OrGLj31MF-pxya4v/embed?start=false&loop=false&delayms=60000'
        });
        setSelectedPart('Part 1');
        setSelectedLink('https://docs.google.com/presentation/d/e/2PACX-1vTyJTrymGCdUZBrFCj3QMOJ9eNN0DEXOjEUuPbDTNLg4DtQXAb2aupLcj8C9X3xQPy5I2Xb5qQi1avE/embed?start=false&loop=false&delayms=60000');
        break;
      case '2':

        setLinkArray({
          'Part 1': 'https://docs.google.com/presentation/d/e/2PACX-1vT8JvGkOcRTM3vgPNcXgP9kWeu2eA34G-Cu6mytrWQtMLMiXX42ugbXdGDL8iWmwlw8DclC2GnVnkQK/embed?start=false&loop=false&delayms=60000',
          'Part 2': 'https://docs.google.com/presentation/d/e/2PACX-1vRKl5IPt-aC4B5SbEsJ0FCQhFQmNpyYz5547s23qeTa4ecl_zzg02UQw5DRKB1pi58-_MH-XqvNwfPA/embed?start=false&loop=false&delayms=60000',
          'Grammar': 'https://docs.google.com/presentation/d/e/2PACX-1vSlAC-w2rbShMA1DGmvvxOaLnUTB5GCGBoKF84DuGWapidzCgvDt90JnRMX6xl8OrGLj31MF-pxya4v/embed?start=false&loop=false&delayms=60000'

        });
        setSelectedPart('Part 1');
        setSelectedLink('https://docs.google.com/presentation/d/e/2PACX-1vT8JvGkOcRTM3vgPNcXgP9kWeu2eA34G-Cu6mytrWQtMLMiXX42ugbXdGDL8iWmwlw8DclC2GnVnkQK/embed?start=false&loop=false&delayms=60000');
        break;
      case '3':
        setLinkArray({
          'Part 1': 'https://docs.google.com/presentation/d/e/2PACX-1vSMRSjZOELsiltu8Y9DTS3sBFvXSNbMOwD3I-6xvyItb_kcCreh_thB1UQto9pne5QPIVHGW8XjX8vF/embed?start=false&loop=false&delayms=60000',
          'Part 2': 'https://docs.google.com/presentation/d/e/2PACX-1vR2otUaCxkFZLDvkcmEoMmrX1gij3-ItOJSV1CezMNdVMUfA6o76UlKI69zI4KI50yPD024WU804NDZ/embed?start=false&loop=false&delayms=60000',
          'Part 3': 'https://docs.google.com/presentation/d/e/2PACX-1vRM0gy2TaWcDoj1LUmydHT34ztfQLFVj0gi2fBIg5GlYQEEPGgz-K-I6XI1wYfrpd4GjDh6p0wMRT9t/embed?start=false&loop=false&delayms=60000',
          'Grammar': 'https://docs.google.com/presentation/d/e/2PACX-1vSlAC-w2rbShMA1DGmvvxOaLnUTB5GCGBoKF84DuGWapidzCgvDt90JnRMX6xl8OrGLj31MF-pxya4v/embed?start=false&loop=false&delayms=60000'
        })
        setSelectedPart('Part 1');
        setSelectedLink('https://docs.google.com/presentation/d/e/2PACX-1vSMRSjZOELsiltu8Y9DTS3sBFvXSNbMOwD3I-6xvyItb_kcCreh_thB1UQto9pne5QPIVHGW8XjX8vF/embed?start=false&loop=false&delayms=60000');
        break;
      case '4':
        setLinkArray({
          'Part 1': 'https://docs.google.com/presentation/d/e/2PACX-1vR8tApugSFtt-ck-3qETV2LffkNDY-9NzSjNvy8JoZdrnk0oB4X8Kry9Kz2vubCUDpUufF8JG1qZZUv/embed?start=false&loop=false&delayms=60000',
          'Part 2': 'https://docs.google.com/presentation/d/e/2PACX-1vQayk21fYTAplYbwOPKV1wLRqjt0sZg40jslM9GCNU0IEoDMjQyTZwSKm6H45_mHK4a3Kk0CUndGiJT/embed?start=false&loop=false&delayms=60000',
          'Grammar': 'https://docs.google.com/presentation/d/e/2PACX-1vSlAC-w2rbShMA1DGmvvxOaLnUTB5GCGBoKF84DuGWapidzCgvDt90JnRMX6xl8OrGLj31MF-pxya4v/embed?start=false&loop=false&delayms=60000'
        })
        setSelectedPart('Part 1');
        setSelectedLink('https://docs.google.com/presentation/d/e/2PACX-1vR8tApugSFtt-ck-3qETV2LffkNDY-9NzSjNvy8JoZdrnk0oB4X8Kry9Kz2vubCUDpUufF8JG1qZZUv/embed?start=false&loop=false&delayms=60000');
        break;
      case '5':
        setLinkArray({
          'Part 1': 'https://docs.google.com/presentation/d/e/2PACX-1vRLGnRf1_yJpfcIeNTibPs6T4y6JwAd9kEVFLwMF59n8nrQPUQmOunMQA1gWX5lmGZUrz6yKPx1ms2s/embed?start=false&loop=false&delayms=60000',
          'Part 2': 'https://docs.google.com/presentation/d/e/2PACX-1vQCULLAbRDJTg9Bt83mWHpmYoCC07LtsPrflxlS4pzxbiWaTDeEkSOnxPzNK2FK8x5-nE4D0bIJTDfm/embed?start=false&loop=false&delayms=60000',
          'Grammar': 'https://docs.google.com/presentation/d/e/2PACX-1vSlAC-w2rbShMA1DGmvvxOaLnUTB5GCGBoKF84DuGWapidzCgvDt90JnRMX6xl8OrGLj31MF-pxya4v/embed?start=false&loop=false&delayms=60000'
        })
        setSelectedPart('Part 1');
        setSelectedLink('https://docs.google.com/presentation/d/e/2PACX-1vRLGnRf1_yJpfcIeNTibPs6T4y6JwAd9kEVFLwMF59n8nrQPUQmOunMQA1gWX5lmGZUrz6yKPx1ms2s/embed?start=false&loop=false&delayms=60000');
        break;
      case '6':
        setLinkArray({
          'Part 1': 'https://docs.google.com/presentation/d/e/2PACX-1vTMKp3hbmiw6xvQmje3re8tG8kIOE6iqM1GZ8jwYgtnvMgITpigUrsl20slNyyOK2RXCqzn9xmGvnRt/embed?start=false&loop=false&delayms=60000',
          'Part 2': 'https://docs.google.com/presentation/d/e/2PACX-1vT56YrebYgWwJTjEVo6t5i7A_4npXeyHDFVuJqLMSc9fS1vYgu95nt7FLdoSsF-ticsHxTNWtNr0dYu/embed?start=false&loop=false&delayms=60000',
          'Grammar': 'https://docs.google.com/presentation/d/e/2PACX-1vSlAC-w2rbShMA1DGmvvxOaLnUTB5GCGBoKF84DuGWapidzCgvDt90JnRMX6xl8OrGLj31MF-pxya4v/embed?start=false&loop=false&delayms=60000'
        })
        setSelectedPart('Part 1');
        setSelectedLink('https://docs.google.com/presentation/d/e/2PACX-1vTMKp3hbmiw6xvQmje3re8tG8kIOE6iqM1GZ8jwYgtnvMgITpigUrsl20slNyyOK2RXCqzn9xmGvnRt/embed?start=false&loop=false&delayms=60000');
        break;

      default:
        setLinkArray({}); 
    }
  }, [props.slide]);


  function handleIframeLoad() {
    setLoading(false);
  }

  const loaderStyle = {
    color: '#5F9D38',
    width: '50px',
    height: '50px',
    margin: '2px'
  };

  const loadStyle = {
    borderRadius: '10px',
    color: 'white',
    width: '500px',
    height: '150px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '200px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '25px',
    backgroundColor: 'rgba(0,0,0,0.1)'
  };

  const divStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const iframeStyle = {
    boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
    margin: '5px',
    borderRadius: '10px',
    border: '3px solid black'
  };

  const buttonStyle = (key) => ({
    padding: '10px 15px',
    margin: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    background: selectedPart === key ? 'lightblue' : '#4CAF50', // Change the colors as per your preference
    color: 'white',
    outline: 'none',
  });
  
  const handleLinkClick = (key, link) => {
    setSelectedPart(key);
    setSelectedLink(link);
    setLoading(true);
  };

  return (
    <div style={divStyle}>
      {Object.keys(linkArray).length > 0 && (
        <ul>
          {Object.keys(linkArray).map((key) => (
            <li key={key}>
              <button
                onClick={() => handleLinkClick(key, linkArray[key])}
                style={buttonStyle(key)}
              >
                {key}
              </button>
            </li>
          ))}
        </ul>
      )}
      {loading ? (
        <div style={loadStyle}>
          <CircularProgress value={100} style={loaderStyle} />
          <h3>Loading..</h3>
        </div>
      ) : ''}
      <iframe style={iframeStyle} src={selectedLink} width="840px" height="539px" onLoad={handleIframeLoad}></iframe>
    </div>
  );
}