import {gql, useQuery,  } from '@apollo/client';

export const All_STUDENTS_QUERY = gql`
  
  query GetAllInValid {
  getAllInValid {
    firstName
    lastName
    email
    createdAt
    message
    id
  }
}
`;

export const CURRENT_USERS_QUERY = gql`
  
  query GetAllValid {
  getAllValid {
    firstName
    lastName
    email
    createdAt
    message
    id
  }
}
`;


export const REMOVE_STUDENT_QUERY = gql`
  
  mutation RemoveStudent($removeStudentId: String!) {
  removeStudent(id: $removeStudentId) {
  id
  username
 
  }
}
`;

 export const MY_CLASS_QUERY = gql`
 query GetMyClass {
  getMyClass {
  id
  students {
    username
    id
  }  
  }
}
`;



 export const  ADD_TO_CLASS = gql`
 mutation AddStudent($addStudentId: String!) {
  addStudent(id: $addStudentId) {
  id  
  }
}`;


export const DELETE_STUDENT = gql`
mutation DeleteStudent($studentId: String!) {
  deleteStudent(id: $studentId)

}
`;

export const REGISTER_USER = gql`
mutation enableUser($teacherId: String!) {
  enableUser(id: $teacherId)
}
`;


 export const DELETE_TEACHER = gql`
 mutation DeleteTeacher($deleteTeacherId: String!) {
  deleteTeacher(id: $deleteTeacherId)
}




`;