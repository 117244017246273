import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client'
import { AuthContext } from '../context/auth';
import { useForm } from '../util/hooks';
import logo from '../imgs/logo.png';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import gql from 'graphql-tag';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const theme = createTheme();




export default function Login() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const { onChange, onSubmit, values } = useForm(loginUserCallback, {

    email: '',
    password: ''

  });

  useEffect(() => {


  });

  function NavReg(){
      navigate("/Register");
  }
  function NavRes(){
    navigate("/ResetPasswordReq");
}





  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    update(
      _,
      {
        data: { login: userData }
      }
    ) {
      context.login(userData);
      if(userData.role == 'ADMIN')
        navigate('/AdminHome')
      else if (userData.role == 'TEACHER')
        navigate('/Content')
    },
    onError(err) {
      toast.error(err.graphQLErrors[0].message, {
        position: toast.POSITION.TOP_CENTER
      });
    },
    variables: values
  });


  function loginUserCallback() {
    loginUser()
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} width={400} />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={onSubmit} className={loading ? 'loading' : ''} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth

              label="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={onChange}
              value={values.email}


            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"

              autoComplete="current-password"
              onChange={onChange}
              value={values.password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{
                backgroundColor: "#395CAA"
              }}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Button 
              fullWidth
              variant="contained"
              style={{
                backgroundColor: "#D13A28"
              }}
              sx={{ mt: 3, mb: 2 }}
              onClick={NavReg}
            >
              Request Access
            </Button>
            <Button 
              fullWidth
              variant="text"
              sx={{ mt: 1, mb: 1 }}
              onClick={NavRes}
            >
              Reset Password
            </Button>

            <Grid container>

            </Grid>
          </Box>

        </Box>
        <ToastContainer />

      </Container>
    </ThemeProvider>
  )
}
 
const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      email
      createdAt
      token
      role
      registered
    }
  }
`;


