import React from 'react';
import { FaFile } from 'react-icons/fa';


import res0 from '../resources/Getting-Started.pdf';
import res0a from '../resources/AcceleratED_Learning_Assessment.pdf';
import res1a from '../resources/Letter_Naming_Letter_Sounds_Center_Activities.pdf';
import res1b from '../resources/Letter_Naming_Letter_Sounds_Word.pdf';
import res1 from '../resources/Sound-Wall-Blank-Graphemes.pdf';
import res2 from '../resources/Sound-Wall-3-5.pdf';
import res3a from '../resources/CVC_Student_Workbook.pdf';
import res3 from '../resources/CVC-Center-Activities.pdf';
import res4 from '../resources/Beyond-Center-Activities.pdf';
import res5 from  '../resources/Long-Vowel.pdf';
import res6 from '../resources/Multisyllabic_Independent_Work.pdf';
import res7 from '../resources/Additional-Literacy-Center-Activities.pdf';
import res8 from '../resources/AcceleratED_Learning_Stories.pdf';
import res9 from '../resources/Decodable-Stories.pdf';
import res10 from '../resources/Progress-Monitoring-Checklists.pdf';
import res11 from '../resources/CVC-Spelling-Lists.pdf';
import res12a from '../resources/Beyond_CVC_Student_Workbook.pdf';
import res12 from '../resources/Beyond-CVC-Spelling-List.pdf';
import res13a from '../resources/Long_Vowel_Student_Workbook.pdf';
import res13 from '../resources/Long-Vowel-Spelling-List.pdf';
import res14 from '../resources/Multisyllabic.pdf';
import res15 from '../resources/Grammar&Writing.pdf';


export function Resources () {
  const pdfFiles = [
    { name: 'Getting Started', url: res0 },
    { name: 'AcceleratED Learning Assessment', url: res0a },
    { name: 'Sound Wall K-2', url: res1 },
    { name: 'Sound Wall 3-5', url: res2 },
    { name: 'Letter Naming Letter Sounds Center Activities', url: res1a },
    { name: 'Letter Naming Letter Sounds Word', url: res1b },
    { name: 'CVC Student Workbook', url: res3a },
    { name: 'CVC Independent Work', url: res3 },
    { name: 'Beyond CVC Independent Work', url: res4 },
    { name: 'Long Vowel Independent Work', url: res5 },
    { name: 'Multisyllabic Independent Work', url: res6},
    { name: 'Additional Literacy Centers', url: res7 },
    { name: 'Fluency Passages', url: res8 },
    { name: 'Decodable Stories', url: res9 },
    { name: 'Progress Monitoring Checklists', url: res10},
    { name: 'CVC Spelling Lists', url: res11 },
    { name: 'Beyond CVC Spelling Lists', url: res12 },
    { name: 'Long Vowel Spelling Lists', url: res12a },
    { name: 'Long Vowel Spelling Lists', url: res13 },
    { name: 'Long Vowel Student Workbook', url: res13a },
    { name: 'Multisyllabic Spelling Lists', url: res14 },
    { name: 'Grammar and Writing', url: res15},
  ];

  return (
    <div>
      <h1>Resources</h1>
      <div className="pdf-thumbnails">
        {pdfFiles.map((pdfFile) => (
          <div className="pdf-thumbnail" key={pdfFile.url}>
            <a href={pdfFile.url} target="_blank">
                <h2><FaFile/>{pdfFile.name}</h2>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

