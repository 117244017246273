import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../context/auth";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import TutorList from "../Components/TutorListed";
import ApprovedUserList from "../Components/ApprovedUserList"
import "react-toastify/dist/ReactToastify.css";

export default function AdminHome() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user.role != 'ADMIN') {
      navigate("/Login");
    }
  });

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ApprovedUserList />
              <TutorList />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}