import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { gql, useMutation } from '@apollo/client';
import { useForm } from '../util/hooks';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';



const theme = createTheme();

export default function ResPassword() {
  const parms = useParams();
  const id = parms.id;
  const token = parms.token;

  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(resetPassword, {
    id: id,
    token: token,
    newPassword: "",
    confPassword: ""
  });


  const [resUserPass, { loading }] = useMutation(RESET_PASSWORD, {
    update(
      _,
      results
    ) {
      console.log("resssss");
      console.log(results)
      toast.success("Password", {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => {
          window.location.href = "/";
        }
      });

    },
    onError(err) {
      console.log(err);
      toast.error(err, {
        position: toast.POSITION.TOP_CENTER
      });
    },
    variables: values
  });

  function resetPassword() {
      console.log('Resetting Password')
      resUserPass();

  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />


        <Box
          className='formBack'
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={onSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  type="password"
                  name="newPassword"
                  required
                  fullWidth
                  id="newPassword"
                  label="New Password"
                  autoFocus
                  value={values.newPassword}
                  error={errors.newPassword ? true : false}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  type="password"
                  name="confPassword"
                  required
                  fullWidth
                  id="confPassword"
                  label="Confirm Password"
                  autoFocus
                  value={values.confPassword}
                  error={errors.confPassword ? true : false}
                  onChange={onChange}
                />
              </Grid>


            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <ToastContainer />
    </ThemeProvider>
  );
}

const RESET_PASSWORD = gql`mutation ResetPassword($id: String!, $token: String!, $newPassword: String!, $confPassword: String!) {
  resetPassword(id: $id, token: $token, newPassword: $newPassword, confPassword: $confPassword)
}`