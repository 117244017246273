import React, { useEffect, useContext, useState } from "react";
import { SketchPad } from "../../node_modules/react-sketchpad/lib/index"
import { AuthContext } from "../context/auth";
import SlideShow from '../Components/SlideShow'
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";


export default function Slides(props) {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showSketchpad, setShowSketchpad] = useState(false);
  let linkVar;
  const toggleSketchpad = () => {
    setShowSketchpad(!showSketchpad);
  };
  useEffect(() => {
    if (!user || user.registered !== 1) {
      navigate("/Login");
    }
  });

  return (
    <div style={{ position: 'relative' }}>
      <button className="DrawingLayerBtns" onClick={toggleSketchpad}>
        {showSketchpad ? 'Clear' : 'Draw'}
      </button>
      <div className="DrawingLayer" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
        {showSketchpad && <SketchPad width={window.innerWidth} height={window.innerHeight} />}
      </div>
      <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
        <Box
          component="main"
          sx={{
            backgroundColor: '#9AD17A',
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <SlideShow slide={props.id} />
          </div>
        </Box>
      </div>
    </div>
  );
}
