import React, { useEffect, useContext } from "react";
import { AuthContext } from "../context/auth";
import { Resources } from '../Components/Resources';
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";


import "react-toastify/dist/ReactToastify.css";

export default function Content() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();


  useEffect(() => {
    if (!user || user.registered !== 1) {
      navigate("/Login");
    }
  });


  const iframeStyle = {
    maxHeight: '600px',
    width: '792px',
    boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
    margin: '5px',
    borderRadius: '10px',
    border: '3px solid black',

  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: '#9AD17A',
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Resources style={iframeStyle} />

      </div>
    </Box>
  );
}