import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "#EAE950",
        paddingTop: ".5rem",
        paddingBottom: ".5rem"
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            
            <Typography color="textSecondary" variant="subtitle1">
            ©{`${new Date().getFullYear()} Angela Citino | All Rights Reserved`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              Unauthorized copying or distribution are expressly prohibited.  AcceleratED Learning and the AcceleratED Learning Logo are trademarks of AcceleratED Learning LLC.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};  

export default Footer;