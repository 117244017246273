import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { gql, useMutation } from '@apollo/client';
import { useForm } from '../../util/hooks';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme();

export default function ResReg(props) {

  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(registerUser, {
    email: '',
  });


  console.log("hereeeee");
  const [regUser, { loading }] = useMutation(RESET_REG, {
    update(
      _,
      results
    ) {
      toast.success("Request Submitted! If your Email is registered we will send you a reset link", {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => {
          window.location.href = "/";
        }
      });

    },
    onError(err) {
      console.log(err);
      toast.error(err.graphQLErrors[0].message, {
        position: toast.POSITION.TOP_CENTER
      });
    },
    variables: values
  });

  function registerUser() {
    console.log('hereere')
    regUser();
  }






  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          className='formBack'
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={onSubmit} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="email"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  autoFocus
                  value={values.email}
                  error={errors.email ? true : false}
                  onChange={onChange}
                />
              </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
              </Grid>
            </Grid>
          </Box>
        </Box>

      </Container>
      <ToastContainer />
    </ThemeProvider>
  );
}

const RESET_REG = gql`mutation ResetPasswordReq($email: String!) {
  resetPasswordReq(email: $email)
}`