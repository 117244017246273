import React, { useContext } from 'react';

import './App.css';
import TermsOfUse from './pages/TermsOfUse';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPasswordReq from './pages/ResetPasswordReq';
import ResPassword from './pages/ResPassword';
import AdminHome from './pages/AdminHome';
import Content from './pages/Content';
import Resources from './pages/Resources';
import Slides from './pages/Slides';
import {
  BrowserRouter,

  Routes,
  Route
} from 'react-router-dom';
import { setContext } from "@apollo/client/link/context";
import { AuthContext } from './context/auth';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { AuthProvider } from '../src/context/auth';
import Header from './Components/Header';
import Footer from './Components/Footer';
const httpLink = createHttpLink({
  uri: 'https://frozen-beyond-57714.herokuapp.com/'
});

const authLink = setContext(() => {
  const token = localStorage.getItem('jwtToken');
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});



export default function App() {

  const { user } = useContext(AuthContext);
  console.log(user)

  return (
    <>
      <ApolloProvider client={client}>
        <AuthProvider>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/Login' element={<Login />} />
              <Route path='/AdminHome' element={<AdminHome />} />
              <Route path='/Content' element={<Content />} />
              <Route path='/Register' element={<Register />} />
              <Route path='/ResetPasswordReq' element={<ResetPasswordReq />} />
              <Route path='/resPassword/:id/:token' element={<ResPassword />} />
              <Route path='/termsOfUse' element={<TermsOfUse />} />
              <Route path='/Resources' element={<Resources />} />

              <Route path='/Slides-1' element={<Slides id='1' />} />
              <Route path='/Slides-2' element={<Slides id='2' />} />
              <Route path='/Slides-3' element={<Slides id='3' />} />
              <Route path='/Slides-4' element={<Slides id='4' />} />
              <Route path='/Slides-5' element={<Slides id='5' />} />
              <Route path='/Slides-6' element={<Slides id='6' />} />

            </Routes>
            <Footer />
          </BrowserRouter>
        </AuthProvider>
      </ApolloProvider >
    </>);
}